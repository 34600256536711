<template>
    <div id="principal" class="container-fluid m-0 p-0">
        <div v-if="telaPronta" class="d-flex flex-column bg-white m-0">
            <!-- Botões de filtro por Requisições(1) e Operações(2) -->
            
            <div class="m-1 sticky-top card-header-filter"></div>

            <div id="idFiltros" class="row px-2">
                
                <!-- Data Inicial -->
                <div class="form-group col-sm-12 col-md-6 my-0">
                    <label class="pb-0 mb-0">Data Inicial</label>
                    <div class="input-group input-group-sm date border">
                        <pikaday-vue-datepicker
                            class="form-control"
                            :class="[{ 'is-invalid': filtros.erros.dataInicial }]"
                            :nome="'datePickerDataInicial'"
                            v-model="filtros.dataInicial"
                            botao="lmxta-filtro-btn-dataInicial"
                        ></pikaday-vue-datepicker>
                        <span class="input-group-append" id="lmxta-filtro-btn-dataInicial">
                            <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
                        </span>
                        <div class="invalid-feedback">
                            <span v-html="filtros.erros.dataInicial"></span>
                        </div>
                    </div>
                </div>
                <!-- Data Final -->
                <div class="form-group col-sm-12 col-md-6 my-0">
                    <label class="pb-0 mb-0">Data Final</label>
                    <div class="input-group input-group-sm date">
                        <pikaday-vue-datepicker
                            class="form-control"
                            :class="[{ 'is-invalid': filtros.erros.dataInicial }]"
                            :nome="'datePickerDataFinal'"
                            v-model="filtros.dataFinal"
                            botao="lmxta-filtro-btn-dataFinal"
                        ></pikaday-vue-datepicker>
                        <span class="input-group-append" id="lmxta-filtro-btn-dataFinal">
                            <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
                        </span>
                        <div class="invalid-feedback">
                            <span v-html="filtros.erros.dataFinal"></span>
                        </div>
                    </div>
                </div>                
                <!-- Empresa -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" >
                    <label class="pb-0 mb-0">Empresa</label>
                    <multiselect
                        v-model="filtros.idEmpresa"
                        :options="empresasUsuario"
                        :multiple="false"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="EmpresaLabel"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].EmpresaLabel }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione uma Empresa</span>
                    </multiselect>
                </div>

                <!--End filtros-->
            </div>
            <!--End filtros-->
            <!-- Botão Voltar -->
            <div class="fixed-bottom bg-white mb-0">
                <div class="btn-group w-100 flex justify-content-end">
                    <button :class="['btn btn-default ', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="$router.push({ name: 'home' })">
                        <i class="fas fa-arrow-left fa-lg pl-2"></i>
                        Voltar
                    </button>
                    <button :class="['btn btn-secondary', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="onFiltrar()">
                        Filtrar 
                        <i class="fas fa-search fa-lg pl-2"></i>
                    </button>
                </div>
            </div>

            <!-- Grid para tratar registros não encontrados ou filtro não aplicado -->
            <div :class="['text-center card-info-search', isMobile ? 'm-2' : 'm-5']">
                <div :class="{ 'd-flex align-items-center justify-content-center mt-2': isMobile }">
                    <div>
                        <span class="text-center">
                            <img :src="iconeSearch" />
                        </span>
                    </div>
                    <div>
                        <span :class="['text-uppercase text-muted', isMobile ? 'fz-70' : 'fz-95']"> Informe os filtros desejados para iniciar a consulta </span>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import pikadayVueDatepicker from '@/components/pikaday-vue-datepicker';
import dataUtils from '@/common/date';

//URLs Icones/Imagens
const imgIconSearch = require('@/assets/Images/linx-icon-ilustrativo/search.png');

export default {
    name: 'filtro-balanco',
    components: {
        pikadayVueDatepicker,
        Multiselect
    },
    data() {
        return {
            tipoMensagem: {
                Sucesso: 1,
                Erro: 2,
                Aviso: 3
            },
            telaPronta: false,
            iconeSearch: imgIconSearch,
            filtros: {
                erros: {},
                dataInicial: null,
                dataFinal: null,
                idEmpresa: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            portaisUsuario: 'auth/portaisUsuario',
            empresasUsuario: 'auth/empresasUsuario',
            filtrosBalanco: 'balanco/filtrosBalanco'
        }),
                
    },
    mounted() {
        this._inicializarFiltroData();
        this.telaPronta = true;
    },

    methods: {
        _inicializarFiltroData() {
            let dtInicial = this.$moment().subtract({month: 1}).format('DD/MM/YYYY');
            let dtFim = this.$moment().format('DD/MM/YYYY');

            dtInicial = this.filtrosBalanco.dataInicial ? this.$moment(this.filtrosBalanco.dataInicial ).format('DD/MM/YYYY') : dtInicial
            dtFim = this.filtrosBalanco.dataFinal ? this.$moment(this.filtrosBalanco.dataFinal ).format('DD/MM/YYYY') : dtFim
            
            this.filtros.idEmpresa = this.empresasUsuario.filter((emp) => emp.Id == this.filtrosBalanco.idEmpresa)[0]
            this.filtros.dataInicial = dtInicial
            this.filtros.dataFinal = dtFim 
        },

        onFiltrar() {
            var self = this;
            if (!self._validarFiltros()) {
                return;
            } else {
                var params = {
                    "idEmpresa": self.filtros.idEmpresa.Id,
                    "dataInicial": dataUtils.converterData(self.filtros.dataInicial), 
                    "dataFinal": dataUtils.converterData(self.filtros.dataFinal)
                    }

                this.$store.dispatch('balanco/atualizaFiltros',params)
                this.$router.push({ name: 'lista-balancos' });
            }
        },

        _validarFiltros() {
            var self = this;
            self.filtros.erros = {};
            self._validarDataInicial();
            self._validarDataFinal();
            self._validarDataFinalMenorQueInicial();
            return Object.keys(self.filtros.erros).length === 0;
        },

        _validarDataFinalMenorQueInicial() {
            var self = this;
            if (self.filtros.dataInicial && self.filtros.dataFinal) {
                if (this.$moment(self.filtros.dataInicial, 'DD/MM/YYYY') > this.$moment(self.filtros.dataFinal, 'DD/MM/YYYY'))
                    self.filtros.erros.dataInicial = 'A data final não pode ser anterior a data inicial';
            }
        },

        _validarDataInicial() {
            var self = this;
            if (!self.filtros.dataInicial || !this.$moment(self.filtros.dataInicial, 'DD/MM/YYYY').isValid()) {
                self.filtros.erros.dataInicial = 'A data inicial precisa ser informada';
            }
        },

        _validarDataFinal() {
            var self = this;
            if (!self.filtros.dataFinal || !this.$moment(self.filtros.dataFinal, 'DD/MM/YYYY').isValid()) {
                self.filtros.erros.dataFinal = 'A data final precisa ser informada';
            }
        },        
    }
};
</script>

<style scoped>
.btn-block-mw {
    max-width: 120px;
}
.card-data {
    /* margin-top: 65px; */
    margin-top: 0px;
}
.card-header-filter {
    /* margin-top: 58px; */
    margin-top: 0px;
    background-color: #f9f9f9;
}
</style>
